import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {PartnerShopsService, ShopInfo} from '@app/core/services/partner-shops.service';
import {AddressInput} from '@app/util/model/address-input';
import {MapInfoWindow} from '@angular/google-maps';
import LatLngLiteral = google.maps.LatLngLiteral;


@Component({
    selector: 'app-shopfinder',
    templateUrl: './shopfinder.component.html',
    styleUrls: ['./shopfinder.component.css'],
    standalone: false
})
export class ShopfinderComponent implements OnInit {

  @Input() reduceHeightBy: number;

  private partnerShops: ShopInfo[] = [];

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  center: google.maps.LatLngLiteral = {
    lat: 51.198482,
    lng: 10.333634
  };
  options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    streetViewControl: false,
  };

  markers = [];
  openedMarker: any;

  // Make MAP full screen, but keep in mind top menu bar / logo bar and footer
  screenHeight: number;
  screenWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight - this.reduceHeightBy;
    this.screenWidth = window.innerWidth;
  }

  constructor(private partnerShopsService: PartnerShopsService) {
  }

  ngOnInit() {
    this.screenHeight = window.innerHeight - this.reduceHeightBy;
    this.partnerShopsService.getSupportedCountries().subscribe(next => {
      this.partnerShops = next;
      this.createMarkers();
      this.calcCenterCoords();
    });
  }

  private createMarkers() {
    if (this.partnerShops) {
      this.partnerShops.forEach(info => {
        this.createMarkersForInfo(info);
      });
    }
  }

  private createMarkersForInfo(info: ShopInfo) {
    info.locations.forEach(location => {
      this.createMarker(info.brandName, location, info.shopUrl);
    });
  }

  private createMarker(brandName: string, p: AddressInput, url: string) {
    if (!p.longitude || p.longitude === 0 || !p.latitude || p.latitude === 0) {
      return;
    }
    let position: LatLngLiteral = {
      lat: p.latitude,
      lng: p.longitude
    };
    let marker = {
      position,
      label: {
        color: 'red',
        text: brandName
      },
      url: url,
      street: p.street + ' ' + p.housenumber,
      city: p.zip + ' ' + p.city,
    };
    this.markers.push(marker);
  }

  private calcCenterCoords() {
    if (this.canInitWithUserCoords()) {
      return;
    }
    this.calcCenterOfShops();
  }

  private canInitWithUserCoords(): boolean {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        return true;
      });
    }
    return false;
  }

  getDisplayUrl(url: string): string {
    if (!url) {
      return null;
    }
    let displayUrl = url;
    displayUrl = displayUrl.replace('https://', '');
    displayUrl = displayUrl.replace('http://', '');
    displayUrl = displayUrl.replace('www.', '');
    return displayUrl;
  }

  private calcCenterOfShops() {
    let length = this.markers.length;
    let centerLat = 0;
    let centerLng = 0;
    this.markers.map(marker => marker.position).filter(position => this.isValidLatLng(position)).forEach(position => {
      centerLat += position.lat;
      centerLng += position.lng;
    });

    this.center = {
      lat: centerLat / length,
      lng: centerLng / length,
    };
  }

  private isValidLatLng(position: any): boolean {
    return !!position.lat && !!position.lng;
  }

  onMarkerClicked(markerElem: any, m: any) {
    this.openedMarker = m;
    this.infoWindow.open(markerElem, true);
  }

  openLink(url: string) {
    window.open(url, '_blank', 'noopener');
  }
}