import {Component} from '@angular/core';

@Component({
    templateUrl: './not-found.component.html',
    standalone: false
})
export class NotFoundComponent {

  constructor() {
  }

}
