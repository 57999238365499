<div class="row">
  <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-3">
    <div class="card shadow-sm">
      <div class="card-body text-center">
        <div *ngIf="!subjectSubmitted">
          <div class="row">
            <div class="col-12">
              <i aria-label="Zurück" class="material-icons float-left align-middle clickable" (click)="goBack()">keyboard_arrow_left</i>
              <h1>Neue Nachricht</h1>
            </div>
          </div>
          <form #form="ngForm">
            <div class="row mt-3">
              <div class="col-12 mt-5">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Betreff</mat-label>
                  <input autocomplete="off" matInput #subjectInput="ngModel" id="subjectInput" type="text"
                         placeholder="Betreff"
                         [(ngModel)]="subject" name="subject" required focus>
                  <mat-error *ngIf="subjectInput.errors?.required && (subjectInput.dirty || subjectInput.touched)"
                  >
                    Bitte geben Sie einen Betreff an.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mt-5">
                <button mat-raised-button color="accent" class="w-100 mt-3" (click)="subjectSubmitted = true"
                        [disabled]="!form.form.valid">Schreiben
                </button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="subjectSubmitted">
          <div class="row">
            <div class="col-12">
              <i aria-label="Zurück" class="material-icons float-left align-middle clickable"
                 (click)="subjectSubmitted = false">keyboard_arrow_left</i>
              <strong>{{ subject }}</strong>
            </div>
          </div>
          <form #form="ngForm" (ngSubmit)="startConversation()">
            <div class="row mt-3">
              <div class="col-12 mt-5">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Ihre Nachricht</mat-label>
                  <textarea autocomplete="off" matInput #inputInput="ngModel" id="inputInput" type="text"
                            placeholder="Ihre Nachricht"
                            [(ngModel)]="message" name="input" focus></textarea>
                </mat-form-field>
                <div class="my-2 text-left">
                  <input type="file" accept="image/png, image/jpeg, text/plain, application/pdf"
                         (change)="handleFileInput($event.target.files)">
                </div>
                <div class="my-3 mat-error text-left" *ngIf="requiredError || fileError || uploadError">
                  <p class="mb-0" *ngIf="fileError">Es gab ein Problem beim Hochladen der Datei.</p>
                  <p class="mb-0" *ngIf="requiredError">Bitte senden Sie entweder eine
                    Nachricht, eine Datei oder beides.</p>
                  <p class="mb-0" *ngIf="uploadError">Dateien müssen das Format .jpeg, .png oder
                    .pdf haben.</p>
                </div>
              </div>
              <div class="col-12 mt-5">
                <button mat-raised-button color="accent" class="w-100 mt-3" type="submit">Senden</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
