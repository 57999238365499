<div class="mb-0 mt-2">
  <div class="row mx-3 mx-sm-4">
    <div class="text-left col-12 col-sm-6 px-0 my-auto">
      <p><strong>Ich möchte zurückzahlen in:</strong></p>
    </div>
    <div class="text-right col-12 col-sm-6 px-0 calculator-emphasis-font">
      <p><strong>{{ currentPlan?.duration }}&nbsp;Monaten</strong></p>
    </div>
  </div>
</div>
<div class="custom-ngx-slider mx-3">
  <ngx-slider [(value)]="value" [options]="options" (userChange)="sliderValueChange($event)"></ngx-slider>
</div>
<div class="calculator-details mt-3 py-3 text-left">
  <div class="mx-auto">
    <div class="row mx-3 mx-md-5">
      <div class="col-6 col-lg-8 px-0 my-auto">
        <p class="mb-0">
          {{ currentPlan?.duration }} monatliche Zahlungen&nbsp;von:
        </p>
      </div>
      <div class="col-6 col-lg-4 px-0 text-right text-lg-left">
        <p class="mb-0">
          <strong>{{ currentPlan?.instalments[0].totalAmount | currency:'EUR':'symbol':'1.2-2' }}</strong></p>
      </div>
    </div>
    <div class="row">
      <hr aria-hidden="true" class="calc__details-line col-9"/>
    </div>
    <div class="row mx-3 mx-md-5">
      <div class="col-12 col-sm-8 px-0 my-auto">
        <p class="mb-0">Nettodarlehensbetrag</p>
      </div>
      <div class="col-12 col-sm-4 px-0">
        <p class="mb-0"><strong>{{ currentPlan?.nominalAmount | currency:'EUR':'symbol':'1.2-2' }}</strong></p>
      </div>
    </div>
    <div class="row mx-3 mx-md-5">
      <div class="col-12 col-sm-8 px-0 my-auto">
        <p class="mb-0">Gesamtbetrag</p>
      </div>
      <div class="col-12 col-sm-4 px-0">
        <p class="mb-0"><strong>{{ currentPlan?.totalAmount | currency:'EUR':'symbol':'1.2-2' }}</strong></p>
      </div>
    </div>
    <div class="row mx-3 mx-md-5">
      <div class="col-12 col-sm-8 px-0 my-auto">
        <p class="mb-0">Sollzins (gebunden)</p>
      </div>
      <div class="col-12 col-sm-4 px-0">
        <p class="mb-0"><strong>{{ terms?.nominalInterest | number:'1.2-2' }}%</strong>&nbsp;p.a.</p>
      </div>
    </div>
    <div class="row mx-3 mx-md-5">
      <div class="col-12 col-sm-8 px-0 my-auto">
        <p class="mb-0">Effektivzins</p>
      </div>
      <div class="col-12 col-sm-4 px-0">
        <p class="mb-0"><strong>{{ currentPlan?.effectiveInterest | number:'1.2-2' }}%</strong>&nbsp;p.a.</p>
      </div>
    </div>
    <div class="row mx-3 mx-md-5">
      <div class="col-12 col-sm-8 px-0 my-auto">
        <p class="mb-0">Zinsfreier Zeitraum</p>
      </div>
      <div class="col-12 col-sm-4 px-0">
        <p class="mb-0">
          <strong>{getInterestFreeRates(), plural, one {30 Tage} other {{{ getInterestFreeRates() }} Raten}}</strong>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 text-center p-3">
    <p class="mb-0">Bonität vorausgesetzt. Diese Angaben entsprechen dem Rechenbeispiel gemäß PAngV. Es
                    besteht ein gesetzliches Widerrufsrecht. Stand: {{ getValidFrom(terms) | safeDate:'MMMM yyyy' }}
    </p>
  </div>
</div>
<product-hint></product-hint>
