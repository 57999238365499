import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    standalone: false
})
export class PageWrapperComponent {

  // If nav logo is not handled via page-wrapper, remove .container class and let caller handle it
  @Input() displayNavLogo: boolean = true;

}
