<div class="card shadow-sm">
  <div class="card-body pb-5">
    <h1>Beispielrechner</h1>
    <img class="img-fluid my-4" ngSrc="assets/icons/calculator.svg" width="48" aria-hidden="true" alt="" height="48"/>
    <p class="my-4">
      Probieren Sie unseren Beispielrechner aus und erfahren Sie, wie gering die Kosten einer Finanzierung
      mit {{ BRAND_NAME }} sind.
    </p>
    <button mat-stroked-button
            color="accent"
            class="par-card-button py-2"
            (click)="goToCalculator()">
      Jetzt rechnen
    </button>
  </div>
</div>
