import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JsonAttachment} from '@app/util/model/json-attachment';
import {DateUtil} from '@app/util/static-services/date.util';
import {FileUtil} from '@app/util/static-services/file.util';
import {ConversationDetailResponse} from 'app/core/backend/response/conversation-detail.response';
import {BackendService} from 'app/core/index';
import {Location} from "@angular/common";

@Component({
    templateUrl: './conversation-detail.component.html',
    styleUrls: ['./conversation-detail.component.css'],
    standalone: false
})
export class ConversationDetailComponent implements OnInit {

  conversation: ConversationDetailResponse;

  newMessage: string;

  attachment: JsonAttachment;

  fileError: boolean;

  requiredError: boolean;

  error: any;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private backendService: BackendService) {
  }

  ngOnInit() {
    this.route.data
        .subscribe((data: { conversation: ConversationDetailResponse }) => {
          this.conversation = data.conversation;
        });
  }

  submit() {
    if (!(this.newMessage || this.attachment)) {
      this.requiredError = true;
      return;
    }
    this.backendService.sendConversation(this.conversation.conversation.conversationId,
        this.conversation.conversation.subject, this.getMessage(), this.getAttachment()).subscribe(next => {
      this.backendService.loadConversation(next).subscribe(conversation => {
        this.conversation = conversation;
        this.newMessage = undefined;
      });
    }, error => {
      this.error = error;
    });
  }

  handleFileInput(files: FileList) {
    let file = files.item(0);
    if (!file) {
      return;
    }
    FileUtil.createBase64ContentString(file).then(value => {
      this.attachment = new JsonAttachment();
      this.attachment.name = file.name;
      this.attachment.contentType = FileUtil.getContentTypeForFile(file);
      this.attachment.content = value;
    }).catch(reason => {
      this.fileError = true;
    });
  }

  goBack() {
    this.location.back();
  }

  asDate(bookingDate: any): Date {
    return DateUtil.makeSafariValid(bookingDate);
  }

  private getMessage() {
    return this.newMessage;
  }

  private getAttachment(): any {
    if (!this.attachment) {
      return null;
    }
    return [this.attachment];
  }

}