import {Component} from '@angular/core';

@Component({
    templateUrl: './not-reachable.component.html',
    standalone: false
})
export class NotReachableComponent {

  constructor() {
  }

}
