import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'safeDate',
    standalone: false
})
export class SafariSafeDatePipe extends DatePipe implements PipeTransform {

  private makeSafariValid(date: any): Date {
    let safariValidDate = date.toString().split('-').join('/');
    return new Date(Date.parse(safariValidDate));
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null | any {
    if (value == null || value === '' || value !== value) {
      return null;
    }
    value = this.makeSafariValid(value).toISOString();
    return super.transform(value, format, timezone, locale);
  }

}
