import {Component} from '@angular/core';
import {environment} from '@env/environment';

@Component({
    selector: 'product-hint',
    templateUrl: './product-hint.component.html',
    standalone: false
})
export class ProductHintComponent {

  public readonly BRAND_NAME: string = environment.brandName;

  constructor() {
  }

}
