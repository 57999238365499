import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ResultcodeTranslationService} from '@app/core/services/resultcode-translation.service';
import {Translation} from '@app/core/model/translation';

@Component({
    selector: 'app-backend-error',
    templateUrl: './backend-error.component.html',
    standalone: false
})
export class BackendErrorComponent implements OnChanges, OnInit {

  @Input()
  error: any;

  @Input()
  overrides: Translation[] = null;

  errorMessage: String;

  constructor(protected service: ResultcodeTranslationService) {
  }

  ngOnInit(): void {
    this.updateErrorMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateErrorMessage();
  }

  private updateErrorMessage() {
    if (this.error) {
      this.errorMessage = this.service.translate(this.error.resultCode, this.overrides);
    } else {
      this.errorMessage = '';
    }
  }
}
