import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {getValidFrom, Terms} from '@app/util/model/terms';
import {ChangeContext, Options} from '@angular-slider/ngx-slider';

@Component({
    selector: 'app-show-plans',
    templateUrl: './show-plans.component.html',
    styleUrls: ['./show-plans.component.css'],
    standalone: false
})
export class ShowPlansComponent implements OnInit, OnChanges {

  @Input() terms: Terms;
  @Input() annuities: PaymentPlan[];
  @Input() desiredDuration = 36;
  @Input() interestFreeRates: number;
  @Input() interestFreeRatesMerchant: number;


  // Remove with design update
  @Input() tmpNewDesign = false;

  @Output() currentPlanChange: EventEmitter<PaymentPlan> = new EventEmitter<PaymentPlan>();

  currentPlan: PaymentPlan;

  currentPlanIndex = 0;

  value = this.desiredDuration;

  interestFreeRatesLocal: number;

  options: Options = {
    showTicksValues: true,
    showSelectionBar: true,
    stepsArray: [
      {value: this.desiredDuration}
    ],
    ariaLabel: 'Schieberegler zur Auswahl der gewünschten Laufzeit.'
  };

  constructor() {
  }

  ngOnInit() {
    if (this.interestFreeRates) {
      this.interestFreeRatesLocal = this.interestFreeRates;
    }
    if (this.annuities) {
      this.initAnnuities();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.interestFreeRates && changes.interestFreeRates.currentValue) {
      this.interestFreeRatesLocal = this.interestFreeRates;
    }
    if (changes.terms && changes.terms.currentValue) {
      this.terms = changes.terms.currentValue;
      this.interestFreeRatesLocal = 0;
      this.interestFreeRatesLocal = this.getInterestFreeRates();
    }
    if (changes.annuities && changes.annuities.currentValue) {
      this.annuities = changes.annuities.currentValue;
      const list = this.annuities.map(function (annuity) {
        return {value: annuity.duration};
      });
      const newOptions: Options = Object.assign({}, this.options);
      newOptions.stepsArray = list;
      this.value = this.desiredDuration;
      this.options = newOptions;
      this.initAnnuities();
    }
  }

  private initAnnuities() {
    let index = this.annuities.findIndex(p => p.duration === this.desiredDuration);
    if (index > 0) {
      this.currentPlanIndex = index;
    }
    this.updateCurrentPlan();
  }

  private updateCurrentPlan() {
    this.currentPlan = this.annuities[this.currentPlanIndex];
    this.currentPlanChange.emit(this.currentPlan);
  }

  sliderValueChange(changeContext: ChangeContext): void {
    this.currentPlan = this.annuities.find(x => x.duration === changeContext.value);
    this.currentPlanChange.emit(this.currentPlan);
  }

  getInterestFreeRates() {
    if (this.interestFreeRatesLocal > 0) {
      return this.interestFreeRatesLocal;
    }

    this.interestFreeRatesLocal = 0;
    if (this.interestFreeRatesMerchant > 0) {
      this.interestFreeRatesLocal += this.interestFreeRatesMerchant;
    }

    if (this.terms) {
      this.interestFreeRatesLocal += this.terms.interestFreeRatesC2;
    }

    return this.interestFreeRatesLocal;
  }

  protected readonly getValidFrom = getValidFrom;
}
