<div class="row">
  <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-3">
    <div class="card shadow-sm">
      <div class="card-body text-center">
        <i aria-label="Zurück" class="material-icons float-left align-middle clickable" (click)="goBack()">keyboard_arrow_left</i>
        <h1>{{ conversation.conversation.subject }}</h1>
        <div class="row mt-3">
          <div class="col-12">
            <ul class="listview">
              <li class="listview-element" *ngFor="let message of conversation.messages">
                <div class="row">
                  <div class="col-12 text-left">
                    <div [class.incoming]="message.type === 'INCOMING'"
                         [class.outgoing]="message.type === 'OUTGOING'">
                      {{ message.text }}
                      <br>
                      <small>
                        {{ asDate(message.creationDate) | safeDate:'dd.MM.yyyy' }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-left">
                    <div [ngClass]="message.type === 'INCOMING' ? 'attachment-incoming' : 'attachment-outgoing'"
                         *ngFor="let attachment of message.attachments">
                      <i class="material-icons text-primary md-24 align-middle">attachment</i>
                      <ng-container *ngIf="attachment.url; else nameOnly">
                        <a href="{{attachment.url}}" target="_blank">{{ attachment.name }}</a>
                      </ng-container>
                      <ng-template #nameOnly>
                        <span>{{ attachment.name }}</span>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-8 offset-sm-2">
            <hr aria-hidden="true">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form #form="ngForm" (ngSubmit)="submit()">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Neue Nachricht</mat-label>
                <textarea autocomplete="off" matInput #inputInput="ngModel" id="inputInput" type="text"
                          placeholder="Neue Nachricht"
                          [(ngModel)]="newMessage" name="input"></textarea>
              </mat-form-field>
              <div class="my-2 text-left">
                <input type="file" accept="image/png, image/jpeg, text/plain, application/pdf"
                       (change)="handleFileInput($event.target.files)" aria-errormessage="convErrMsg">
              </div>
              <div class="my-3 mat-error text-left" *ngIf="requiredError || fileError || error" id="convErrMsg">
                <p class="mb-0" *ngIf="fileError">Es gab ein Problem beim Hochladen der Datei.</p>
                <p class="mb-0" *ngIf="error">Es gab ein Problem beim Versenden der
                  Nachricht.</p>
                <p class="mb-0" *ngIf="requiredError">Bitte senden Sie entweder eine
                  Nachricht, eine Datei oder beides.</p>
              </div>
              <button mat-raised-button color="accent" type="submit" class="w-100 mt-3">Senden</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
