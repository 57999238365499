import {Directive, Input} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn
} from '@angular/forms';

@Directive({
    selector: '[equalTo]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EqualToValidator, multi: true }],
    standalone: false
})
export class EqualToValidator implements Validator {

    @Input('equalTo') other: AbstractControl;

    validate(control: UntypedFormControl) {
        return this.areEqual(this.other)(control);
    }

    areEqual(other: AbstractControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value === other.value ? null : {equalTo: true};
        };
    }
}