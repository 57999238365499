<mat-form-field appearance="outline" class="w-100">
  <mat-label>IBAN</mat-label>
  <input matInput #ibanInput="ngModel" id="ibanInput" type="text" placeholder="IBAN" [ngModel]="ibanModel | iban"
         (ngModelChange)="ibanModel=$event" name="iban" required focus>

  <mat-error *ngIf="ibanInput.errors?.required && (ibanInput.dirty || ibanInput.touched)">
    Bitte geben Sie eine IBAN an.
  </mat-error>
</mat-form-field>
<button *ngIf="isSubmit" class="w-100" mat-raised-button color="accent" (click)="submit()"
        [disabled]="!form.form.valid" type="submit">
  OK
</button>