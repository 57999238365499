import { Injectable } from '@angular/core';
import { StringUtil } from '@app/util/static-services/string.util';


@Injectable()
export class TemplateService {

  constructor() { }

  private _subject: string;

  private _message: string;

  reset() {
    this._subject = null;
    this._message = null;
  }

  get subject(): string {
    return this._subject;
  }

  set subject(val: string) {
    this._subject = val;
  }

  get message(): string {
    return this._message;
  }

  set message(val: string) {
    this._message = val;
  }
  isFilled(): boolean {
    return StringUtil.isNotBlank(this._subject);
  }
}
