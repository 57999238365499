import {ContentType} from '@app/util/model/content-type';
import {JsonAttachment} from '@app/util/model/json-attachment';

export class FileUtil {

  static createBlob(attachment: JsonAttachment): Blob {
    let buffer = this.base64ToArrayBuffer(attachment.content);
    return new Blob([buffer], {type: ContentType[attachment.contentType]});
  }

  static openFileInNewTab(file: JsonAttachment) {
    let blob = FileUtil.createBlob(file);
    FileUtil.openBlobInNewTab(blob);
  }

  static openBlobInNewTab(blob: Blob) {
    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob);
    } else {
      window.open(URL.createObjectURL(blob), '_blank');
    }
  }

  private static base64ToArrayBuffer(data: string) {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      data = data.replace(/\s/g, '');
    }
    let binaryString = window.atob(data);
    let length = binaryString.length;
    let bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  private static arrayBufferToBase64(data: any) {
    let bytes = new Uint8Array(data);
    let asString = '';
    let length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      asString += String.fromCharCode(bytes[i]);
    }
    return window.btoa(asString);
  }

  static getContentTypeForFile(file: File): string {
    return Object.keys(ContentType).find(k => ContentType[k] === file.type);
  }

  static createBase64ContentString(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing file'));
      };

      reader.onload = () => {
        resolve(this.arrayBufferToBase64(reader.result));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  static downloadJsonFile(json: JsonAttachment) {
    let blob = FileUtil.createBlob(json);
    this.download(blob, json.name);
  }

  static downloadBlobFile(blob: Blob, fileName: string) {
    this.download(blob, fileName);
  }

  private static download(blob: Blob, fileName: string) {
    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, fileName);
    } else {
      let file = new File([blob], fileName, {type: blob.type});
      let anchor = document.createElement('a');

      document.body.appendChild(anchor);
      anchor.href = window.URL.createObjectURL(file);
      anchor.download = fileName;
      anchor.click();
    }
  }
}
