<div class="card shadow-sm max-height">
  <div class="card-body pb-5">
    <h1>Weitere Informationen</h1>
    <img class="img-fluid my-4" ngSrc="assets/icons/info.svg" width="48" aria-hidden="true" alt="" height="48"/>
    <p class="my-4">
      Sie haben Fragen zu dem {{ BRAND_NAME }}? Weitere Informationen finden Sie in dem Bereich FAQ.
    </p>
    <a class="par-card-link" href="https://sofortkredit.vwfs.de/faq.html" target="_blank">Zu den FAQ</a>
  </div>
</div>
