import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {DateInput} from '@app/util/model/date-input';

@Component({
    selector: 'date-input-component',
    templateUrl: './date-input.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class DateInputComponent implements OnInit {

  @Input() dateInput: DateInput;

  @Input() isSubmit: boolean;

  @Input() form: NgForm;

  @Output() dateInputChange = new EventEmitter<DateInput>();

  @Input() focus: boolean;

  @ViewChild('dayInput', { read: ElementRef, static: true }) dayRef: ElementRef;

  @ViewChild('monthInput', { read: ElementRef, static: true }) monthRef: ElementRef;

  @ViewChild('yearInput', { read: ElementRef, static: true }) yearRef: ElementRef;


  ngOnInit(): void {
    if (focus) {
      this.dayRef.nativeElement.focus();
    }
  }

  constructor() {
  }

  submit(): void {
    if (this.isSubmit) {
      this.dateInputChange.emit(this.dateInput);
    }
  }

  focusNext(event: any, nextRef: ElementRef) {
    let value: string = event.target.value;
    if (value.length === 2) {
      nextRef.nativeElement.focus();
    }
  }
}
