import {Component} from '@angular/core';

@Component({
    selector: 'required-disclaimer',
    templateUrl: './required.disclaimer.component.html',
    styleUrls: ['./required.disclaimer.component.css'],
    standalone: false
})
export class RequiredDisclaimerComponent {

}
