import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
    selector: '[focus]',
    standalone: false
})
export class FocusDirective implements OnInit {

  constructor(private ele: ElementRef) {
  }

  ngOnInit() {
    setTimeout(p => {
      this.ele.nativeElement.focus();
    }, 100);

  }

}