import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConversationListResponse} from '@app/core/backend/response/conversation-list.response';
import {Conversation} from '@app/util/model/conversation';

@Component({
    templateUrl: './conversations.component.html',
    styleUrls: ['./conversations.component.css'],
    standalone: false
})
export class ConversationsComponent implements OnInit {

  conversations: Conversation[];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.data
        .subscribe((data: { conversations: ConversationListResponse }) => {
          this.conversations = data.conversations.conversations;
        });
  }

}
