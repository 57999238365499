import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {IbanUtil} from '@app/util/static-services/iban.util';

@Component({
    selector: 'iban-input-component',
    templateUrl: './iban-input.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class IbanInputComponent implements OnInit {

  @Input() ibanModel: string;

  @Input() isSubmit: boolean;

  @Input() form: NgForm;

  @Output() ibanModelChange = new EventEmitter<string>();

  @Input() focus: boolean;

  @ViewChild('ibanInput', { read: ElementRef, static: true }) inputRef: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
    if (focus) {
      this.inputRef.nativeElement.focus();
    }
  }

  submit(): void {
    if (this.isSubmit) {
      this.ibanModelChange.emit(this.ibanModel);
    }
  }
}
