<mat-form-field appearance="outline" class="w-100">
  <mat-label>E-Mail-Adresse</mat-label>
  <input autocomplete="email" matInput #emailInput="ngModel" id="emailInput" type="email" placeholder="E-Mail-Adresse"
         focus
         [(ngModel)]="email" name="email" required pattern="\s*[^\s]+@[^\s]+[.][^\s]+\s*">

  <mat-error *ngIf="emailInput.errors?.required && (emailInput.dirty || emailInput.touched)">
    Bitte geben Sie eine E-Mail-Adresse an.
  </mat-error>
  <mat-error *ngIf="emailInput.errors?.duplicate">
    Unter dieser E-Mail-Adresse gibt es bereits einen Account.
  </mat-error>
</mat-form-field>
<button *ngIf="isSubmit" class="w-100" mat-raised-button color="accent" (click)="submit()"
        [disabled]="!form.form.valid" type="submit">
  OK
</button>
