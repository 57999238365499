import {Component} from '@angular/core';
import {environment} from '@env/environment';

@Component({
    templateUrl: './error.component.html',
    standalone: false
})
export class ErrorComponent {

  private readonly DOMAIN: string = environment.domainName;

  public customerServiceMailTo: string;

  constructor() {
    this.customerServiceMailTo = 'mailto:' + this.getCustomerServiceMail();
  }


  public getCustomerServiceMail(): string {
    return 'service@' + this.DOMAIN;
  }

}
