export class Terms {
  currency: string;
  limit: number;
  nominalInterest: number;
  effectiveInterest: number;
  interestFreeRatesC2: number;
  productType: string;
  durations: number[];
  minPurchaseAmount: number;
  validFrom: Date;
}

export function getValidFrom(terms: Terms): Date {
  if (terms.validFrom!!) {
    return new Date(terms.validFrom);
  }
  return new Date(2024, 1, 1);
}
