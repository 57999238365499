import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BackendService} from '@app/core';
import {JsonAttachment} from '@app/util/model/json-attachment';
import {FileUtil} from '@app/util/static-services/file.util';
import {TemplateService} from '../template.service';
import {Location} from "@angular/common";

@Component({
    templateUrl: './new-conversation.component.html',
    styleUrls: ['./new-conversation.component.css'],
    standalone: false
})
export class NewConversationComponent implements OnInit {

  subject: string;

  subjectSubmitted: boolean;

  message: string;

  attachment: JsonAttachment;

  fileError: boolean;

  requiredError: boolean;

  uploadError: any;


  constructor(private router: Router,
              private location: Location,
              private route: ActivatedRoute,
              private backendService: BackendService,
              private templateService: TemplateService) {
  }

  ngOnInit() {
    if (this.templateService.isFilled()) {
      this.message = this.templateService.message;
      this.subject = this.templateService.subject;
      this.templateService.reset();
      this.subjectSubmitted = true;
    }

  }

  startConversation() {
    if (!(this.message || this.attachment)) {
      this.requiredError = true;
      return;
    }
    this.backendService.sendConversation(undefined, this.subject, this.message).subscribe(
        next => {
          this.router.navigate(['./conversation', next], {relativeTo: this.route});
        },
        error => {
          this.uploadError = true;
        }
    );
  }

  goBack() {
    this.location.back();
  }

  handleFileInput(files: FileList) {
    let file = files.item(0);
    if (!file) {
      return;
    }
    FileUtil.createBase64ContentString(file).then(value => {
      this.attachment = new JsonAttachment();
      this.attachment.name = file.name;
      this.attachment.contentType = FileUtil.getContentTypeForFile(file);
      this.attachment.content = value;
    }).catch(reason => {
      this.fileError = true;
    });
  }

}
