<div class="row">
  <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-3">
    <div class="card shadow-sm">
      <div class="card-body text-center">
        <h1>Support</h1>
        <div class="row mt-3">
          <div class="col-12">
            <ul class="listview bordered">
              <li class="listview-element" *ngFor="let conversation of conversations">
                <a [routerLink]="['./conversation', conversation.conversationId]">
                  <div class="row">
                    <div class="col-10 text-left">
                      {{ conversation.subject }}
                      <br>
                      <small>
                        {{ conversation.editDate | safeDate:'dd.MM.yyyy' }}
                      </small>
                    </div>
                    <div class="col-1 text-primary align-self-center">
                      <i class="material-icons align-middle">remove_red_eye</i>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <p *ngIf="!conversations || conversations.length <= 0">
              Keine Nachrichten vorhanden</p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <button mat-raised-button color="accent" class="px-4 w-100" [routerLink]="['./conversation/new']">
              Neue Nachricht
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>