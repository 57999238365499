<div class="row">
  <div class="col-3 pr-0">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>TT</mat-label>
      <input autocomplete="bday-day" matInput #dayInput="ngModel" id="dayInput" type="tel" placeholder="TT"
             [(ngModel)]="dateInput.day"
             name="day" required pattern="[0-9]{2}" maxlength="2" max="31" (input)="focusNext($event, monthRef)" focus>
    </mat-form-field>
  </div>
  <div class="col-3 px-1">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>MM</mat-label>
      <input autocomplete="bday-month" matInput #monthInput="ngModel" id="monthInput" type="tel" placeholder="MM"
             [(ngModel)]="dateInput.month"
             name="month" required pattern="[0-9]{2}" maxlength="2" max="12" (input)="focusNext($event, yearRef)">
    </mat-form-field>
  </div>
  <div class="col-6 pl-0">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Jahr</mat-label>
      <input autocomplete="bday-year" matInput #yearInput="ngModel" id="yearInput" type="tel" placeholder="Jahr"
             [(ngModel)]="dateInput.year" name="year" required pattern="[0-9]{4}" maxlength="4" min="1900">

    </mat-form-field>
  </div>
  <div class="col-12">
    <button *ngIf="isSubmit" class="w-100" mat-raised-button color="accent" (click)="submit()"
            [disabled]="!form.form.valid" type="submit">
      OK
    </button>
  </div>
</div>
