import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'email-input-component',
    templateUrl: './email-input.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class EmailInputComponent implements OnInit, AfterViewInit {

  @Input() email: string;

  @Input() isSubmit: boolean;

  @Input() form: NgForm;

  @Output() emailChange = new EventEmitter<string>();

  @Input() focus: boolean;

  @ViewChild('emailInput', { read: ElementRef, static: true }) emailInput: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
    if (this.focus) {
      this.emailInput.nativeElement.focus();
    }
  }

  submit(): void {
    if (this.isSubmit) {
      this.emailChange.emit(this.email);
    }
  }

  ngAfterViewInit(): void {
  }
}
