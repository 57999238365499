import {Component} from '@angular/core';
import {environment} from '@env/environment';

@Component({
    selector: 'more-information',
    templateUrl: './more-information.component.html',
    styleUrls: ['./more-information.component.css'],
    standalone: false
})
export class MoreInformationComponent {

  public readonly BRAND_NAME: string = environment.brandName;

}
