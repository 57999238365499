<div class="text-center" [class.gaussed]="loading">
  <h1>Wählen Sie Ihre monatlichen Raten</h1>
  <div class="mt-3">
    <app-show-plans [terms]="terms" [annuities]="annuities" [desiredDuration]="desiredDuration"
                    [interestFreeRates]="interestFreeRates" [interestFreeRatesMerchant]="interestFreeRatesMerchant"
                    (currentPlanChange)="currentPlan = $event"></app-show-plans>
    <div class="mt-4">
      <button mat-raised-button color="accent" class="px-5" (click)="setRate()"
              *ngIf="!isOverlay; else overlayButton">
        Rate speichern
      </button>
      <ng-template #overlayButton>
        <button mat-raised-button color="accent" class="px-5" (click)="setRate()">
          Weiter einkaufen
        </button>
      </ng-template>
    </div>
  </div>
  <div *ngIf="loading">
    <div class="text-center spinner mt-5">
      <img src="assets/icons/spinner.svg" class="rotate-center" width="70" aria-hidden="true" alt="">
      <div class="mt-5">
        <strong>Wir rechnen gerade, bitte haben Sie einen Moment Geduld.</strong>
      </div>
    </div>
  </div>
</div>

