<div class="row" [class.gaussed]="loading">
  <div class="col-12">
    <div class="mt-4">
      <div class="row">
        <div class="col-12 text-center">
          <h1>Wunschbetrag eingeben</h1>
        </div>
      </div>
      <div class="col-12 px-5">
        <form [formGroup]="amountForm" (ngSubmit)="submit();toggleInput()">
          <div class="row">
            <div class="col-12 col-md-9">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>EUR</mat-label>
                <input matInput type="number" placeholder="EUR"
                       name="amountControl" required focus max="{{terms.limit}}" min="{{terms.minPurchaseAmount}}"
                       formControlName="amountControl">
                <mat-hint *ngIf="terms?.limit">
                  Max. {{ terms.limit| currencyPostfix }}
                </mat-hint>
                <mat-error *ngIf="amountForm.get('amountControl').hasError('min') && amountForm.touched">
                  Bitte geben Sie einen Betrag an.
                </mat-error>
                <mat-error
                    *ngIf="amountForm.get('amountControl').hasError('min') || amountForm.get('amountControl').hasError('max')">
                  Bitte geben Sie einen Betrag zwischen {{ terms?.minPurchaseAmount | currencyPostfix }} und
                  {{ terms?.limit | currencyPostfix }} ein.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <button mat-raised-button color="accent" type="submit" class="w-100"
                      [disabled]="!amountForm.valid">OK
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>
    <app-show-plans [terms]="terms" [annuities]="annuities"></app-show-plans>
  </div>
</div>
<div class="text-center spinner" *ngIf="loading">
  <div class="spinner-container">
    <img src="assets/icons/spinner.svg" class="rotate-center" width="70" aria-hidden="true" alt="">
    <div class="mt-5">
      <p>
        <strong>Wir rechnen gerade,<br> bitte haben Sie einen Moment Geduld.</strong>
      </p>
    </div>
  </div>
</div>
