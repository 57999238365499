export enum ContentType {
  PDF = 'application/pdf',
  RTF = 'application/rtf',
  CSV = 'text/csv',
  TXT = 'text/plain',
  VM = 'text/plain',
  FTL = 'text/plain',
  XML = 'application/xml',
  JRXML = 'application/jrxml',
  MT940 = 'text/plain',
  JS = 'application/javascript',
  ZIP = 'application/zip',
  DTAZV = 'text/plain',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  JSON = 'application/json',
  MP4 = 'video/mp4',
  WEBM = 'video/webm',

}