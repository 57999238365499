import {Component} from '@angular/core';

@Component({
    selector: 'app-tuv-logo',
    templateUrl: './tuv-logo.component.html',
    standalone: false
})
export class TuvLogoComponent {

}
